import * as React from "react"

function SvgLogo(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 42 40" fill="none" {...props}>
      <path
        d="M7.587 20.087c0-7.336 5.823-13.1 13.057-13.1 7.058 0 12.88 5.764 12.88 13.1 0 6.987-5.823 12.926-12.88 12.926-7.234 0-13.057-5.939-13.057-12.926zm-7.587 0C0 31.092 9.351 40 20.644 40c11.292 0 20.467-8.908 20.467-19.913C41.111 8.908 31.936 0 20.644 0 9.352 0 0 8.908 0 20.087z"
        fill="#E54A2D"
      />
    </svg>
  )
}

export default SvgLogo

